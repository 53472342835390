import styled from 'styled-components';
import { device } from '@retina-packages/retina-theme-bootstrap/packages/css/device';

export const ConsiderationsForTesting = styled.div`

&.hcp-consideration-container {
	.footer-block {
		&.footer-block-bg {
			@media ${device.desktopsignup} {
				padding: 0 !important;
			}

			.footer-block--row {
				.footer-inner-block {
					a {
						@media ${device.desktopsignup} {
							font-size: 2.2rem !important;
							line-height: 2.4rem !important;
							font-family: "Gotham", Arial, Helvetica, sans-serif !important;
						}
					}
				}
			}
		}
	}
}

.headerwraphead {
	.topnav {
		.professional-icon-wrapper {
			a {
				@media ${device.laptopMedium} {
					font-size: 1.4rem;
					line-height: 1.8rem;
				}

				@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
					font-size: 1.8rem;
					line-height: 2.6rem;
				}
			}
		}
	}
}

.consideration-testing {
	.carouselSlides {
		.slick-slider {
			max-width: 950px;
			margin: 0px auto;

			.slick-prev,.slick-next {
				display: none !important;
			}

			.slick-list {
				margin: 0;

				.slick-track {
					.slick-slide {
						margin: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}
		}
	}
}

.header-inner {
	.col-lg-10 {
		width: 86.944%;

		@media ${device.laptopL} {
			width: 81.944%;
		}

		@media ${device.extraLargeDesktop} {
			width: 87%;
		}

		.navbar-nav {
			@media ${device.extraLargeDesktop} {
				left: 75px;
			}

			@media ${device.verylargeDesktop} {
				left: 100px;
			}

			li {
				@media ${device.extraLargeDesktop} {
					padding: 0 4rem;
				}

				&:nth-child(4) {
					position: relative;
					left: 15px;

					@media ${device.desktopmenu} {
						left: 0;
					}

					@media ${device.extraLargeDesktop} {
						width: 235px;
					}
				}

				&:nth-child(6) {
					a {
						@media ${device.extraLargeDesktop} {
							padding: 0 10px;
						}

						@media ${device.verylargeDesktop} {
							padding: 0 45px;
						}
					}
				}
			}
		}
	}
}

	@media ${device.laptopMedium} {
		.show {
			+.main {
				padding-top: 100px;
			}
		}
	}
	@media ${device.ipadLandscapemin} and ${device.desktopmenu} {
		.show {
		  +.main {
				padding-top: 100px;
		  }
		}
	}
	&.hcp-consideration-container {

		div.video-container-consideration-for-testing {
			@media ${device.laptopair2max} {
				padding-left: 30px;
				padding-right: 30px;
			}

			.considerations-for-testing-video-section {
				~.o-container {
					@media ${device.desktopsignup} {
						padding: 0;
					}
				}
			}
		}

		#footerRefConId {
			ol {
				padding-left: 5px;

				li {
					margin: 0 0 20px 20px;
    			padding: 0 0 0 16px;

					@media ${device.laptopMedium} {
						text-align: left;
						font-size: 1.4rem;
						line-height: 1.8rem;
						margin: 0 0 5px 20px;
					}
				}
			}
		}

		footer {
			padding: 0 5rem !important;

			@media ${device.laptopMedium} {
				padding: 0 2rem !important;
			}

			.show-hide {
				padding-top: 0;
			}
			.left-footer {
				padding-top: 70px;

				@media ${device.laptopMedium} {
					padding-top: 0;
				}
			}
			.col-lg-4 {
				padding-top: 70px;

				@media ${device.laptopMedium} {
					padding-top: 0;
				}
			}
			.o-copyright {
				padding-top: 20px;
			}
			.do-not-sell {
				.gtm-footer {
					color: #00FFD9 !important;
				}
			}
		}

		.internal-banner__large-title {
			p {
				line-height: 6.4rem;

				@media ${device.laptopMedium} {
					line-height: 3.8rem;
				}
			}
		}
    .clinical-trials-banner {
			.internal-banner {
				&:after {
					@media ${device.ipadLandscape} {
						height: 32px;
					}
				}
			}
    }
    /* .footercontainer {
      margin-top: 0;

			.c-hide-references {
				padding-top: 30%;

				@media ${device.smalldeskstrt} {
					padding-top: 27%;
				}

				@media ${device.desktopStart} {
					padding-top: 28%;
				}

				@media ${device.ipadLandscape} {
					padding: 10rem 0 0;
				}
			}

			.row{
				padding-top: 0;
			}
    }
    footer {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 3rem;
			@media ${device.laptopMedium} {
				padding: 0 5rem;
			}
			@media ${device.ipadLandscape} {
				max-width: 718px;
			}
			@media ${device.laptopPros} {
				padding: 0 1rem;
			}
    } */

		.considerations-for-testing-gene-mods {
			padding: 90px 0 80px;
			background: #f7f7f7;

			@media ${device.laptopMedium} {
				padding-bottom: 48px !important;
    		padding-top: 48px !important;
			}

			.o-container {
				@media ${device.laptopMedium} {
					padding: 0;
				}
			}

			h2 {
				margin: 30px 0;
				text-align: center;
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;

				@media ${device.laptopMedium} {
					font-size: 2.5rem;
    			line-height: 3rem;
					margin-top: 0 !important;
				}
			}

			.faq-block {
				align-items: center;
    		margin: 50px 0 0;
				max-width: 1440px;
				padding-top: 44px;
				display: flex;
				flex-wrap: wrap;
				position: relative;

				.genes-faq-content__left {
					@media ${device.laptopMedium} {
						width: 100% !important;
    					margin: 0 !important;
						padding: 0;
					}
				}

				.genes-faq-media__right {
					display: grid;
					align-items: center;
					width: 50%;
					padding-right: calc((100% - 1168px) / 2);
					padding-left: 18px;

					@media ${device.laptopMedium} {
						padding-left: 0px;
						width: 100%;
						padding: 20px 0 0;
					}

					.o-header {
						color: #8000BE;
						font-size: 2.7rem;
						font-weight: 600;
						line-height: 3.8rem;
						margin-bottom: 0;

						@media ${device.laptopMedium} {
							margin-top: 15px;
							text-align: center;
							font-size: 2rem;
							line-height: 2.4rem;
						}

						sup {
							font-size: 60%;
						}
					}

					.listing-section {
						margin-top: 30px !important;
						margin: auto;
						display: table;
						padding: 0;

						@media ${device.laptopMedium} {
							padding-left: 30px;
							padding-right: 30px;
							margin-top: 0 !important;
						}

						li {
							display: inline-block;
							position: relative;
							padding: 2px 0 12px 25px;
							color: #000000;
							font-size: 2.2rem;
							font-weight: 500;
							line-height: 3.2rem;
							list-style: none;
							font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

							@media ${device.laptopMedium} {
								padding: 5px 0 5px 25px;
								font-size: 1.8rem;
    						line-height: 2.6rem;
								text-align: left;
								display: block;
							}

							&:before {
								top: 16px;
								content: "";
								position: absolute;
								background-color: #8000BE;
								width: 8px;
								height: 8px;
								margin: auto;
								left: 0;

								@media ${device.laptopMedium} {
									top: 13px;
								}
							}
							 p {
								@media ${device.laptopMedium} {
									margin:0;
								}
							 }
						}
					}
				}
				a {
					&:hover {
						font-weight: bold;
					}
				}
			}

			~.o-container {
				@media ${device.laptopMedium} {
					padding: 0 2rem;
				}
				@media ${device.laptopPros} {
					padding: 0 3rem !important;
				}
				@media (max-width: 1359.98px) {
					padding: 0 5rem;
				}
			}
		}

		#consideration-find-a-gen-con {
			> .o-container {
				@media ${device.laptopMedium} {
					padding: 0 3rem;
				}
				@media  ${device.tablet} and ${device.laptopair2max} {
					padding: 0 5rem;
				}
				.step-info-box {
					@media ${device.laptopMedium} {
						padding: 30px 20px 30px;
					}
					@media  ${device.tablet} and ${device.laptopair2max} {
						padding: 30px 100px 30px;
					}
				}
			}
		}

		.info-box-2 {
			p {
				width: 84%;
    		margin: 0 auto;

				@media ${device.laptopMedium} {
					width: 100%;
				}

				span {
					display: unset !important;
					padding-bottom: 0 !important;
				}
			}
		}
		.info-box {
			max-width: 1120px !important;
		}
		.step-info-box {
			max-width: 1020px;
			margin-left: auto;
			margin-right: auto;
			margin: 120px auto 0;
			flex-wrap: wrap;
			width: 100%;
			display: flex;
			align-items: center;
			background-color: #300064;
			padding: 40px 70px;
			position: relative;

			@media ${device.laptopMedium} {
				padding: 30px 10px 30px;
				margin-top: 48px !important;
				margin: 50px auto 0;
				justify-content: center;
				display: block;
				padding-left: 2rem;
    		padding-right: 2rem;
				max-width: 354px;
			}

			@media  ${device.tablet} and ${device.laptopair2max} {
				max-width: 618px;
			}

			p, h2 {
				text-align: center;
				margin: 0 30px 0;
				color: #fff;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				margin: 0 auto;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 1.8rem;
    			line-height: 2.6rem;
					width: 82%;
    			margin: 0 auto;
				}

				span {
					color: #00FFD9;
					padding-top: 0;
    			display: flex;
					padding-bottom: 12px;

					@media ${device.laptopMedium} {
						display: unset;
					}
				}

				sup {
					font-size: 60%;
				}
			}
			a {
				&:after {
					width: 18px;
    			height: 18px;

					@media ${device.ipadLandscapemin} and ${device.minideskend} {
						top: 19px;
						margin-left: 11px;
					}

					@media ${device.laptopPros} {
						top: 13px;
					}
					@media ${device.tablet} and ${device.laptopMedium} {
						top: 14px;
					}
					@media ${device.laptop} {
						top: 18px;
						margin-left: 11px;
					}
				}
			}
		}
		.considerations-for-testing-location-box {
			img {
				position: absolute;
    		left: 100px;
    		width: 68px;
				top: 80px;

				@media ${device.laptopMedium} {
					position: relative !important;
					top: 0;
					left: 0 !important;
					display: block;
					margin: auto;
					width: 40px;
				}
			}
			h2 {
				margin: 0;
			}
			p, h2 {
				text-align: left;
				padding: 0 0 0 130px;

				@media ${device.desktopsignup} {
					margin: unset;
				}

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 0 !important;
					text-align: center !important;
					margin-top: 10px;
					font-size: 1.8rem;
    			line-height: 2.6rem;
					margin-left: 0;
				}

				span {
					@media ${device.laptopMedium} {
						font-size: 2rem;
						line-height: 2.4rem;
						padding: 0 0 10px 0;
						width: 90%;
						margin: auto;
						margin-top: 16px;
						display: block;
					}
				}
			}
			a {
				color: #fff;
				padding: 10px 0 0 130px;
				position: relative;
				text-decoration: underline;
				text-underline-offset: 3px;
				cursor: pointer;
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 0 !important;
					text-align: center !important;
					margin-top: 0;
					font-size: 1.8rem;
					line-height: 2.6rem;
					display: block;
				}

				&:hover {
					color: #00FFD9;
					text-decoration: none;
				}
			}
			.locator-link {
				padding: 0 0 0 30px;

				@media ${device.laptopMedium} {
					padding: 0;
				}
			}
		}

		.considerations-for-testing-primary-bg {
			position: relative;
    	margin: 5rem 0 10rem;

			@media ${device.laptopMedium} {
				margin: 5rem 0rem 10rem;
			}

			&:before {
				height: 60%;
    		top: 145px;
				content: "";
				display: block;
				background: #00FFD9;
				width: 100%;
				position: absolute;

				@media ${device.laptopMedium} {
					height: 70%;
				}
			}
		}

		.video-section {
			display: block;
			text-align: center;
			background-color: #8000BE;
			padding-left: 100px;
			padding-right: 100px;
			padding-top: 88px !important;

			@media ${device.laptopMedium} {
				padding-top: 48px !important;
				padding-left: 20px;
				padding-right: 20px;
				width: 100%;
    			margin: 0 auto;
			}

			.video-section__inner-content {
				padding-bottom: 100px;
				text-align: center;

				@media ${device.laptopMedium} {
					padding-bottom: 48px !important;

				}

				h2 {
					padding-bottom: 40px;
					color: #fff !important;
					font-size: 4.2rem;
					font-weight: 600;
					line-height: 5rem;

					@media ${device.laptopMedium} {
						padding-bottom: 0;
						font-size: 2.7rem;
    				margin-bottom: 0;
						line-height: 3.7rem !important;
					}
				}

				p {
					margin: 0 auto 30px;
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: #fff;
					text-align: center;
					max-width: 768px;
					font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
					font-weight: 500;

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.6rem;
						padding-top: 25px;
						margin: 0 !important;
					}

					small {
						font-size: 80%;
    					font-weight: 400;
					}

					a {
						color: #fff;
						text-decoration: underline;
						text-underline-offset: 5px;
						cursor: pointer;
						font-family: 'Gotham', Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
							font-weight: 500;
						}

						&:hover {
							color: #00FFD9;
							text-decoration: none;
						}

						&:after {
							content: '';
							width: 20px;
							height: 20px;
							position: absolute;
							margin-top: 9px;
							margin-left: 5px;

							@media ${device.laptopMedium} {
								margin-top: 4px;
							}
						}
					}
				}
				.affordable-section {
					.media--image {
						display: none;
					}
				}
			}
		}

		.footer-block.footer-block-bg {
			background-color: #8000BE;
			padding: 0 100px;
			display: flex;

			@media ${device.laptopMedium} {
				display: block;
				margin-top: -1px;
				margin-bottom: -32%;
				margin-left: auto;
				margin-right: auto;
				padding: 0 46px;
				width: 100%;
			}

			@media  ${device.tablet} and ${device.laptopair2max} {
				margin-left: auto;
				margin-right: auto;
			}

			.footer-block--row {
				margin: auto;
				display: flex;

				@media ${device.laptopMedium} {
					display: block;
				}

				.footer-inner-block {
					border-radius: 10px;
					background-color: #fff;
					padding: 25px 20px 120px;
					position: relative;
					margin: 0 15px;
					z-index: 100;
					width: 50%;
					max-width: 449px;

					@media ${device.laptopMedium} {
						padding-bottom: 30px !important;
						width: 100%;
						margin: 30px auto 0;
						padding: 16px 20px 120px;
					}

					@media ${device.tablet} and ${device.ipadLandscape} {
						margin: 0 auto !important;
						display: table;
						max-width: unset;
					}

					&:first-child {
						margin-left: 0;

						@media ${device.laptopMedium} {
							margin-left: auto;
							margin-top: 0;
						}
					}
					&:last-child {
						margin-right: 0;

						@media ${device.tablet} and ${device.ipadLandscape} {
							margin-top: 30px !important;
						}

						img {
							@media ${device.laptopMedium} {
								height: 85px !important;
							}
						}
					}
					&::before {
						content: "";
						background: #00FFD9;
						width: 100%;
						height: 16px;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 9px 9px 0 0;
					}

					img {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;

						@media ${device.laptopMedium} {
							height: 100px;
							padding: 16px 0;
						}
					}

					h3 {
						font-weight: 500;
						min-height: 76px;
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;

						@media ${device.laptopMedium} {
							min-height: unset;
							font-size: 2rem;
    					line-height: 2.2rem;
							width: 90%;
							text-align: center;
							display: table;
							margin: 0 auto;
						}
					}

					p {
						text-align: center;
    				padding: 24px 0 0 0;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}
					}

					a {
						position: absolute;
						width: 90%;
						bottom: 25px;
						text-align: center;
						box-shadow: 0 3px 4px #d6d6d6;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 2rem;
						background: #8000BE;
						color: #fff;
						border: 1px solid #fff;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						letter-spacing: 0;
						border-radius: 6px;
						transition: all 0.3s ease;
    				cursor: pointer;
						font-family: 'Gotham', Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							position: relative;
							left: 0;
							bottom: 0;
							margin-top: 32px;
							width: 100%;
							display: block;
							padding: 10px;
							font-size: 18px;
							line-height: 2.6rem;
						}
						@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
							margin-left: -6px;
							width: 93%;
						}

						&:hover {
							background: #fff;
							color: #8000BE;
							border-color: #fff;
							font-weight: 700;
						}

						&:after {
							content: '';
							width: 16px;
							height: 14px;
							display: inline-block;
							margin-left: 6px;
							position: relative;
							top: 2px;

							@media ${device.laptopMedium} {
								display: block;
								margin: 0 auto;
							}
						}
					}
				}
			}
		}

	}
	#discussing-post-content {
		.footer-block {
			padding: 0 100px;
			display: flex;
			margin-bottom: -24%;

			@media ${device.laptopMedium} {
				padding: 0 46px;
				display: block;
				margin-top: -1px;
				margin-bottom: -32%;
			}

			.footer-block--row {
				margin: auto;
				display: flex;

				@media ${device.laptopMedium} {
					display: block;
				}

				.footer-inner-block {
					border-radius: 10px;
					background-color: #fff;
					padding: 25px 20px 120px;
					position: relative;
					margin: 0 15px;
					z-index: 100;
					width: 50%;
					max-width: 449px;
					box-shadow: 0 2px 2px rgb(17 17 17 / 16%);

					@media ${device.laptopMedium} {
						padding-bottom: 30px !important;
						width: 100%;
						margin: 30px auto 0;
						padding: 25px 20px 120px;
					}

					@media ${device.mobilemidmax} {
						max-width: 261px;
					}

					&:first-child {
						margin-left: 0;

						@media ${device.laptopMedium} {
							margin-left: auto;
							margin-top: 0;
						}

						&:before {
							background: linear-gradient(90deg,#8000be 0%,#8000be 45%,#594dc6 100%);
						}

						img {
							@media ${device.laptopMedium} {
								height: 85px;
							}
						}

					}
					&:last-child {
						margin-right: 0;

						@media ${device.laptopMedium} {
							display: table;
							margin: 0 auto;
							margin-top: 30px;
						}

						&:before {
							background: linear-gradient(90deg,#3398ce 0%,#3398ce 25%,#17d0d4 74%,#06f2d7 100%);
						}

						source {
							display: none;
						}
						.gatsby-image-wrapper-constrained {
							display: table;
							margin: 0 auto;

							>div {
								max-width: 70px !important;
							}
						}
					}

					&:before {
						content: "";
						width: 100%;
						height: 16px;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 9px 9px 0 0;
					}

					source {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;

						@media ${device.laptopMedium} {
							height: 100px;
						}
					}

					img {
						margin: auto;
						text-align: center;
						display: block;
						padding: 10px 0 20px 0;
						height: 100px;
						width: auto;

						@media ${device.laptopMedium} {
							height: 100px;
						}
					}
					h3 {
						font-weight: 500;
						min-height: 76px;
						font-size: 2.7rem;
						line-height: 3.8rem;
						color: #8000BE;
						text-align: center;

						@media ${device.laptopMedium} {
							min-height: unset;
							font-size: 2rem;
    					line-height: 2.2rem;
						}
					}
					p {
						text-align: center;
						padding: 24px 0 0 0;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						font-size: 2.2rem;
						font-weight: 500;
						line-height: 3.2rem;
						color: #000000;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
    					line-height: 2.6rem;
						}
					}
					a {
						position: absolute;
						width: 90%;
						bottom: 25px;
						text-align: center;
						box-shadow: 0 3px 4px #d6d6d6;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 2rem;
						background: #8000BE;
						color: #fff;
						border: 1px solid #fff;
						font-weight: 500;
						line-height: 3.2rem;
						text-decoration: none;
						z-index: 1;
						vertical-align: top;
						overflow: hidden;
						padding: 10px 25px;
						letter-spacing: 0;
						border-radius: 6px;
						transition: all 0.3s ease;
						cursor: pointer;

						@media ${device.laptopMedium} {
							position: relative;
							left: 0;
							bottom: 0;
							margin-top: 32px;
							width: 100%;
							display: block;
							padding: 10px;
							font-size: 18px;
						}

						&:hover {
							background: #fff;
							color: #8000BE;
							border-color: #fff;
							font-weight: 700;
						}

						&:after {
							content: '';
							width: 16px;
							height: 14px;
							display: inline-block;
							margin-left: 6px;
							position: relative;
							top: 2px;

							@media ${device.laptopMedium} {
								display: block;
								margin: 0 auto;
							}
						}
					}
				}
			}
		}
	}

	.tab-section {
		padding: 110px 0 0 0;
		@media ${device.laptopMedium} {
			padding: 82px 0 0 0;
		}
		@media ${device.tablet} and ${device.laptopair2max} {
			width: 91%;
			margin: 0 auto;
		}

		.card {
			@media ${device.laptopMedium} {
				padding-bottom: 0px;
			}
			.tabbable {
				.tab-content {
					.generic-slider-no-image {
						.swiper-pagination {
							.swiper-pagination-bullet {
								color: #8000BE !important;

								&.active {
									background-color: #8000BE !important;
								}
							}
						}
					}
				}
			}
		}
	}
	.carouselSlides {
		>div.o-container {
			@media ${device.laptopMedium} {
				padding: 0;
			}
		}
	}
	.tabbable-responsive {
		overflow: hidden !important;
		max-width: 95%;
    margin: 0 auto;
		min-width:95% !important;

		@media ${device.laptopMedium} {
			overflow-x: hidden !important;
			padding: 0 1rem;
			min-width: 100% !important;
		}

		.tabbable {
			ul {
				li {
					button {
						p {
							@media ${device.laptopair2max} {
								margin-bottom: 0;
							}
						}
					}
				}
			}
		}

		.slick-slide {
			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				margin: 0;
			}
		}

		.nav-tabs {
			@media ${device.laptopMedium} {
				margin-bottom: 30px !important;
			}
			.nav-link {
				@media ${device.laptopMedium} {
					font-size: 1.8rem;
    			line-height: 2rem;
				}
				br {
					display: none;

					@media ${device.laptopMedium} {
						display: block;
					}
				}

				p {
					margin-bottom: 0;
					br {
						display: none;

						@media ${device.laptopMedium} {
							display: block;
						}
					}
				}
			}
		}
		.slick-slide {
			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				margin: 0 !important;
			}
		}
		#tabbed-content-tabpane-tab0 {
			.carouselSlides {
				margin-top: 40px;

				@media ${device.laptopMedium} {
					margin-top: 0;
				}

			}
			span.swiper-pagination-bullet {
				&:first-child {
					&:after {
						content: "Exame clínico";
					}
				}
				&:nth-child(2) {
					&:after {
						content: "Trabalhe junto com um Geneticista";
					}
				}
				&:nth-child(3) {
					&:after {
						content: "Teste genético apropriado";
					}
				}
				&:nth-child(4) {
					&:after {
						content: "Realização do teste genético";
						max-width: 196px;
					}
				}
				&:nth-child(5) {
					&:after {
						content: "Discuta o resultado com seu paciente";
					}
				}
				&:after {
					@media ${device.tablet} and ${device.laptopair2max} {
						margin-left: 18px;
						width: 179px;
					}
					@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
						margin-left: 18px;
						width: 179px;
					}
				}
			}

		}
		#tabbed-content-tabpane-tab1 {
				.slick-slide {
					.result-main-section {
						@media ${device.laptopMedium} {
							padding-top: 80px;
						}
					}
				}

			.generic-slider-no-image {
				.swiper-pagination {
					width: 62%;

					@media ${device.laptopMedium} {
						width: 100%;
					}
				}
			}

			.form-card {
				@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
					width: 91%;
				}
			}

			span.swiper-pagination-bullet {
				&:first-child {
					&:after {
						content: "Antes do teste genético";
					}
				}
				&:nth-child(2) {
					&:after {
						content: "Após o teste genético";
					}
				}
				&:nth-child(3) {
					&:after {
						content: "Se o teste genético for negativo ou inconclusivo";
					}
				}
			}
			span {
				p {
					font-size: 4.2rem;
					font-weight: 700;
					line-height: 5rem;
					color: #000000;
					text-align: center;
					padding-top: 80px;
					max-width: 950px;
					margin: 0 auto;
					padding-bottom: 50px;

					@media ${device.laptopMedium} {
						text-align: center;
						font-size: 2.5rem;
						line-height: 3rem;
						padding: 0;
					}

					sup {
						font-size: 60%;
					}
				}
			}
			.result-title {
				position: absolute;
				font-weight: 500;
				font-size: 2.7rem;
				line-height: 3.4rem;
				padding-bottom: unset;
				color: #8000BE;

				@media ${device.laptopMedium} {
					text-align: center;
					font-size: 22px;
					line-height: 26px;
					position: relative;
				}

				.black-font {
					color: #000000;
				}
			}
			img {
				@media ${device.laptopMedium} {
					position: relative;
					width: 50px;
					margin-bottom: 20px;
				}
			}
			#discuss-1 {
				ul {
					@media ${device.laptopMedium} {
						padding-top: 50px;
					}
				}
			}

			ul {
				padding-top: 75px;
    			padding-left: 0;

				@media ${device.laptopMedium} {
					padding-top: 20px;
				}
			}
			.carouselSlides {
				.btn-wrapper-slider {
					display: none;
				}
			}
			.btn-wrapper-slider {
				padding-bottom: 50px;
				margin: 15px auto 30px;

				@media ${device.laptopMedium} {
					margin: 0px auto 30px;
				}

				@media ${device.laptopMedium} {
					width: 85%;
					margin: 0 auto;
					display: block;
					padding-bottom: 20px;
				}

				.tab-button1 {
					width: auto;
					overflow: hidden;
    				padding: 27px 25px;
					box-shadow: 0 3px 4px #d6d6d6;
					text-decoration: none;

					@media ${device.laptopMedium} {
						margin: 0px auto 15px;
						font-size: 1.8rem;
    					line-height: 2.6rem;
						display: block;
    					height: auto;
						text-align: center;
						max-width: 281px;
						padding: 13px 20px;
					}

					&:after {
						content: '';
						width: 16px;
						height: 14px;
						display: inline-block;
						margin-left: 6px;
						position: relative;
						top: 1px;
						@media ${device.laptopMedium} {
							margin: 0 auto;
							display: block;
						}
					}
				}
			}
			.swiper-pagination {
				span {
					color: transparent !important;

					&:before {
						content: "";
						display: inline-block;
						color:
					}
					&:first-child {
						&:before {
							width: 18px;
    					height: 14px;
							background-position: center;
							background-repeat: no-repeat;
							background-size: cover;
						}
					}
					&:nth-child(2) {
						&:before {
							content: "+";
							font-weight: 500;
							line-height: 40px;
    					font-size: 3.4rem;
							color: #8000BE;

							@media ${device.laptopMedium} {

							}
						}
					}
					&:nth-child(3) {
						&:before {
							content: "?";
							font-weight: 500;
							line-height: 40px;
    					font-size: 3.4rem;
							color: #8000BE;

							@media ${device.laptopMedium} {

							}
						}
					}
					&.active {
						&:before {
							color: #fff !important;
						}
					}
				}
			}
		}
		div.step-to-gen.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
			span.swiper-pagination-bullet {
				@media ${device.laptopMedium} {
					margin: -5px !important;
				}
			}
		}

		.step-to-gen.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
			max-width: 906px !important;

			span.swiper-pagination-bullet {
				width: 60px !important;
				height: 60px !important;
				background-color: #7323CA;
				margin: 0px !important;
				opacity: 1;
				color: #8000BE;
				position: relative;
				display: flex;
				align-items: center;
				justify-items: center;
				border-radius: 100%;
				font-weight: 700;
				line-height: 40px;
				font-size: 3.4rem;
				display: block;
				margin: auto;

				@media ${device.laptopMedium} {
					width: 40px !important;
					height: 40px !important;
					margin: -5px !important;
				}

				&.active {
					background-color: rgb(115, 35, 202) !important;
    			color: #fff !important;
				}

				&:after {
					font-size: 2.2rem;
					color: #8000BE;
					position: absolute;
					top: 70px;
					left: -81px;
					width: 211px;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.laptopMedium} {
						display: none;
					}
				}
			}
		}
		.swiper-progress-bar-fill {
			position: absolute !important;
			height: 8px;
			z-index: -1;
			background-color: rgb(115, 35, 202) !important;
		}
		.slick-list {
			margin: 0 35px;
			@media ${device.laptopMedium} {
				margin: 0;
			}
			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				margin: 0;
			}
		}
		.slick-active {
			@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
				margin: 0 !important;
			}
		}
		.slick-slide {
			max-width: 950px;
			margin: 0 70px;
			margin-right: 70px;
			margin-left: 12px;

			@media ${device.laptopMedium} {
				margin: 0;
			}

			>div {
				margin-left: 32px;
				margin-right: 32px;
				width: 100%;

				@media ${device.laptopMedium} {
					margin-left: 0;
					margin-right: 0;
				}

				@media ${device.laptopair2max} {
					margin-left: 0;
					margin-right: 0;
				}
			}

			.result-main-section {
				padding-top: 245px;

				@media ${device.laptopMedium} {
					padding-top: 65px;
				}

				@media ${device.ipadLandscapemin} and ${device.miniTopDeskMax} {
					padding-top: 265px;
				}

				.wizard-title {
					display: none;
					@media ${device.laptopMedium} {
						display: block;
						color: #8000BE;
						font-size: 1.8rem;
						line-height: 2.6rem;
						margin-bottom: 20px;
						text-align: center;
					}
				}
				.form-card {
					padding-left: 100px;
    			padding-bottom: 30px;
					display: flex;
					position: relative;

					@media ${device.laptopMedium} {
						display: block;
						padding-bottom: 10px;
						padding-left: 0;
					}

					img {
						top: 0;
						position: absolute;
						left: 0;
						vertical-align: middle;
    				border-style: none;
						margin: auto;
						display: block;

						@media ${device.laptopMedium} {
							position: relative;
							margin: auto;
							width: 30px;
							margin-bottom: 16px;
						}
					}

					p {
						padding: 0 0 20px 10px;
						line-height: 3rem;
						color: #000000;
						text-align: left;
						font-family:'Gotham-Book', Arial, Helvetica, sans-serif;
						font-size: 2.2rem;
    				font-weight: 500;

						@media ${device.laptopMedium} {
							padding: 0 0 10px 0;
    					text-align: center;
							font-size: 1.8rem;
						}

						@media ${device.ipadLandscapemin} and ${device.laptopair2max} {
							text-align: center;
						}

						strong {
							font-family: 'Gotham', Arial, Helvetica, sans-serif;
						}
						sup {
							font-size: 60%;
						}
					}
				}
				ul {
					margin-top: 0;
					margin: auto;
					display: grid;
					padding-left: 1rem;

					@media ${device.laptopMedium} {
						padding-left: 3px;
					}

					li {
						padding: 8px 0 8px 25px;
						line-height: 3rem;
						display: inline-block;
						position: relative;
						font-size: 2.2rem;
						font-weight: 500;
						list-style: none;
						font-family:'Gotham-Book', Arial, Helvetica, sans-serif;

						@media ${device.laptopMedium} {
							font-size: 1.8rem;
						}

						&:before {
							content: "";
							position: absolute;
							background-color: #8000BE;
							width: 8px;
							height: 8px;
							top: 20px;
							margin: auto;
							left: 0;
						}
						sup {
							font-size: 60%;
						}
					}
				}

				.slider-end-copy {
					color: #8000BE;
					line-height: 3.2rem;
					font-size: 2.2rem;

					@media ${device.laptopMedium} {
						font-size: 18px;
						line-height: 22px;
						text-align: center;
					}
				}
			}
		}

		.btn-wrapper-slider {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: 50px;

			.button {
				width: 145px;
				height: 48px;
				background: #8000BE;
				margin: 0px 15px 30px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				font-size: 2.2rem;
				color: #fff;
				border: 1px solid #fff;
				box-shadow: 0 3px 4px #d6d6d6;
				cursor: pointer;
				line-height: 2.4rem;

				@media ${device.laptopMedium} {
					margin: 0px 8px 30px;
					line-height: 3.3rem;
				}

				&:hover {
					background: rgb(255, 255, 255);
					color: rgb(128, 0, 190);
					border-color: rgb(255, 255, 255);
					font-weight: 700;
				}
			}
			.forward-btn {
				&:after {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-left: 4px;
				}
			}
			.back-btn {
				&:before {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-right: 4px;
					transform: rotate(-180deg);
				}
			}
		}

		.slider-end-paragraph {
			text-align: center;
			line-height: 3rem;
			font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
			font-size: 2.2rem;
    	font-weight: 500;
			padding-bottom: 138px;

			@media ${device.laptopMedium} {
				font-size: 1.8rem;
				line-height: 2rem !important;
				padding-bottom: 48px !important;
			}
		}
	}

	.consideration-testing {
    .o-header--h1 {
			p {
				padding-left: 25px;
				@media ${device.ipadLandscape} {
					padding-left: 0;
				}
			}

      @media ${device.laptopMedium} {
        max-width: 100% !important;
      }

      @media ${device.ipadLandscapemin} and ${device.desktopmenu} {
        max-width: 70% !important;
      }

      @media ${device.desktopsignup} {
        max-width: 65% !important;
      }
    }

    .internal-banner__contant {
			position: absolute;
			left: 0;
			width: 100%;
			top: 50%;
			transform: translateY(-50%);
			z-index: 99;

			@media ${device.ipadLandscape} {
				top: 40px;
				transform: translateY(0);
			}
    }

    .o-container-considerations {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding: 0 3rem;

			.c-genetic--american {
				padding: 35px 0px;
				margin-bottom: 8rem;
				margin-top: 8rem;
				max-width: 950px;
				margin-left: auto;
				margin-right: auto;

				.o-paragraph {
					font-size: 2.5rem;
					font-weight: 600;
					line-height: 3.2rem;
					color: #000000;
					text-align: center;
				}
			}

			.c-genetic--test {
				padding: 35px 60px;
				position: relative;
				background-color: #f7f7f7;
				box-shadow: 0 4px 6px #cecece;
				margin-bottom: 8rem;
				margin-top: 8rem;
				max-width: 950px;
				margin-left: auto;
				margin-right: auto;
				margin: 0 70px;

				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;
				}

				.o-paragraph-inner {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;
					color: #000000;

					span {
						font-weight: bold;
					}
				}

				.o-text-link {
					text-transform: unset;
					font-size: 2.2rem;
					line-height: 3.2rem;
					text-decoration: none;
					position: relative;
					display: inline-block;
					color: #000000;
					font-weight: 700;
					padding-bottom: 3px;
					margin-top: 14px;
				}

				p {
					a {
						color: #8000BE;
						font-weight: 500;
					}
				}

				.thirdPartyLink {
					cursor: pointer;
				}

				.o-text-link {
					a {
						color: #8000BE;
						text-decoration: underline;
					}

				}

				@media ${device.ipadLandscape} {
					margin: 40px 0 0;
					padding: 20px;
				}

      }

			.c-genetic--test::before {
				content: "";
				width: 16px;
				height: 100%;
				display: block;
				background: linear-gradient(-180deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
				position: absolute;
				top: 0;
				right: 100%;
				box-shadow: 0 4px 6px #cecece;

				@media ${device.ipadLandscape} {
					content: "";
					width: 100%;
					height: 10px;
					background: linear-gradient(-188deg, #8000be 0%, #594dc6 35%, #17d0d4 64%, #00ffd9 100%);
					top: 0;
					left: 0;
					right: unset;
					box-shadow: none;
				}
			}

			@media ${device.midDesktopMax} {
				padding: 0 5rem;
			}

			@media ${device.ipadLandscape} {
				max-width: 718px;
				font-size: 2rem;
				line-height: 2.6rem;
			}

			@media ${device.laptopPros}{
				padding: 0 3rem;
			}
    }
	}

	.internal-banner {
		&:after {
			top: unset;
		}
	}

	.emerging-science-when-you-test {
    margin: 120px auto 0;

    @media ${device.laptopPros} {
      padding: 0 2.7rem;
    }
    .o-inner {
      padding: 0 100px;

		@media ${device.laptopMedium} {
			padding: 0;
		}

			.specialist-infobox.info-box {
				margin-bottom: 120px;
				padding: 32px 80px;

				@media ${device.laptopMedium} {
					margin-bottom: 28px;
					padding: 24px 42px;
				}

				.media--image {
					@media ${device.minimumDesk} {
						padding-bottom: 80px;
					}
				}
			}

			.info-box {
				display: -ms-flexbox;
				display: flex;
				-webkit-align-items: center;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				background-color: #300064;
				padding: 27px 102px;
				position: relative;
				margin: auto;
				width: 100%;
				text-align: center;

				@media ${device.laptopMedium} {
					display: block;
				}

				img {
					position: absolute;
					left: 100px;
					max-width: 100%;

					@media ${device.laptopMedium} {
						display: block;
    				margin: 0 auto 10px;
						width: 45px;
						left: 0;
						right: 0;
						text-align: center;
						position: unset;
            top: 25px;
					}
				}

				.o-paragraph {
					padding: 0 0 0 120px;
					font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
					font-weight: 700;
					text-align: left;
					color: #fff;
					line-height: 3.2rem;
					font-size: 2.2rem;
					margin: 0;

					@media ${device.laptopMedium} {
						padding: 0;
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						line-height: 2rem;
						font-size: 2rem;
						display: inline-block;
						text-align: center;
						margin-top: 10px;
					}

					strong {
						color: #00FFD9;
						display: inline-block;
						font-size: 2.7rem;
						padding-bottom: 12px;
						font-weight: 600;

						@media ${device.laptopMedium} {
							line-height: 2.2rem;
							padding-bottom: 32px;
							font-size: 2rem;
						}
					}

				}

				.link-right-arrow {
					cursor: pointer;
					background-color: #fff;
					width: 40px;
					height: 40px;
					line-height: 46px;
					text-align: center;
					border-radius: 50%;
					margin: 5px 0 0 28px;

					@media ${device.laptopMedium} {
						display: flex;
						margin: 20px auto 0;
						justify-content: center;
						align-items: center;
						position: initial;
					}

					&:hover {
						transform: scale(1.2);
						transition: ease 5ms;
					}

					img {
						display: block;
    				margin: 13px auto;
						max-width: 100%;
						position: static;
						left: 0;

						@media ${device.laptopMedium} {
							margin: 0;
							width: auto;
						}
					}
				}
			}
		}
	}


	.c-gene-modification {
		padding-bottom: 0px;
		padding-top: 60px;

		@media ${device.ipadLandscape} {
			text-align: center;
			padding: 40px 0 !important;
			padding-bottom: 0 !important;
		}

    .faq-block {
			flex-wrap: inherit;
			align-items: center;
			margin: 0 auto;
			max-width: 1440px;
			padding-top: 44px;
			display: flex;
			position: relative;

			@media ${device.ipadLandscape} {
				display: block;
			}

			.genes-faq-content__left {
				width: calc(50% - 30px);
				margin: 0 30px 0 0;

				@media ${device.ipadLandscape} {
					width: 100%;
					margin: 0;
					padding-left: 25px;
					padding-right: 25px;
				}

				h2 {
					text-align: left;
					margin: 0;
					color: #000000;
					font-family: 'Gotham', Arial, Helvetica, sans-serif;

					@media ${device.ipadLandscape} {
						margin-top: 0px !important;
						font-size: 2.2rem;
						padding-bottom: 20px;
						line-height: 3rem;
						text-align: center;
					}
				}
				.gatsby-image-wrapper {
					width: 100%;
				}
      		}

			.genes-faq-media__right {
				display: grid;
				align-items: center;
				width: 50%;

				@media ${device.ipadLandscape} {
					width: 100%;
					padding-top: 20px;
					padding: 20px 0 0;

					.gatsby-image-wrapper {
						width: 100%;
						vertical-align: middle;
						border-style: none;
					}
				}
			}

			.o-header--h2 {
				font-size: 4.2rem;
				font-weight: 600;
				line-height: 5rem;
				color: #000000;
			}

			.o-padding-left-full {
				padding-left: calc((100% - 1042px) / 2);
				@media ${device.ipadLandscape} {
					padding-left: 25px;
				}
			}

			h2 {
				color: #000000;
				text-align: left;
				padding-bottom: 40px;
			}

			.o-text--book {
				font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
			}

			.o-paragraph {
				font-size: 2.2rem;
				font-weight: 500;
				line-height: 3.2rem;
				color: #000000;
				text-align: left;

				@media ${device.ipadLandscape} {
					font-size: 1.8rem;
					line-height: 2.6rem;
					text-align: center;
				}

				sup {
					font-size:60%;
				}
			}

			a {
				color: #8000BE;
				text-decoration: underline;

				&:hover {
					font-weight: bold;
				}
			}
    }
	}

	.video-container-consideration-for-testing {
		padding-top: 88px;
		max-width: 1188px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3rem;
		padding-right: 3rem;
	}
	.consideration-footer-o-container {
		&.o-container {
			max-width: 1188px;
			margin-left: auto;
			margin-right: auto;
			padding-left: 3rem;
			padding-right: 3rem;
		}
	}

	.footer {
			@media ${device.mobileMedium} {
				margin-top: 0;
			}

			@media ${device.ipadLandscape} {
				margin-top: 0;
			}
	}

`
export const FooterCalloutContainer = styled.div`

	.padding-cards-fix {
		@media ${device.ipadLandscape} {
			max-width: 718px;
			padding: 0 2rem;
			font-size: 2rem;
			line-height: 2.6rem;
		}

		@medai ${device.laptopPros} {
			padding: 0 3rem;
		}
	}

	.steptogenetic-footer--block {
		padding: 0 100px;
		display: flex;
		margin-bottom: -24%;

		.o-container {
			max-width: 1188px;
			margin: unset;
			margin-left: auto;
			margin-right: auto;
			padding: 0;
		}

		@media ${device.ipadLandscape} {
			padding: 0 46px !important;
			display: block;
			margin-top: -1px;
			margin-bottom: -32%;
			max-width: 718px;

		}

		@media ${device.laptopMedium} {
			display: block;
			padding: 0;
		}

		.footer-block--row {
			display: flex;
			img {
				margin: auto;
				text-align: center;
				display: block;
				padding: 10px 0 20px 0;
				height: 100px;
				width: auto;
			}

			@media ${device.laptopMedium} {
				display: block;
			}

			.footer-inner-block {
				border-radius: 10px;
				margin: 0 15px;
				text-align: center;
				position: relative;
				border-radius: 5px;
				background-color: #fff;
				box-shadow: 0px 2px 4px rgb(17 17 17 / 16%);
				padding: 25px 20px 120px;
				margin: 0 15px;
				z-index: 992;

				@media ${device.laptopMedium} {
					width: 100%;
					padding-left: 20px;
					padding-right: 20px;
					padding-bottom: 20px;
					margin-bottom: 20px;
				}

				@media ${device.ipadLandscape} {
					padding-bottom: 30px !important;
					width: 100%;
					margin: 30px auto 0;
				}

				&:first-child {
					margin-left: 0;
					&:before {
						background: linear-gradient(90deg, #8000be 0%, #8000be 45%, #594dc6 100%);
					}

					@media ${device.laptopMedium} {
						margin-left: auto;
						margin-top: 0;
					}
				}

				&:last-child {
					margin-right:0;

					&:before {
						background: linear-gradient(90deg, #3398ce 0%, #3398ce 25%, #17d0d4 74%, #06f2d7 100%);
					}
					.o-common-title {
						&:before {
							width: 90px;
						}
					}
					@media ${device.laptopMedium} {
						margin-left: 0;
					}

				}

				&:before {
					content: "";
					background: linear-gradient(90deg,#8000be 0%,#8000be 45%,#00FFD9 100%);
					width: 100%;
					height: 16px;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 5px 5px 0 0;
				}

				.o-common-title {
					font-weight: 700;
					font-size: 2.7rem;
					line-height: 3.8rem;
					color: #8000BE;
					text-align: center;
					margin-bottom: 30px;

					@media ${device.ipadLandscape} {
						font-size: 2rem;
						line-height: 2.2rem;
						font-weight: 500;
					}
				}

				.o-common-para {
					padding: 10px 5px 30px 5px;
					font-size: 2.2rem;
					line-height: 3.2rem;
					color: #000000;
					text-align: center;

					@media ${device.ipadLandscape} {
						font-size: 1.8rem;
						line-height: 2.6rem;
						font-weight: 500;
						padding: 0;
					}
				}

				a {
					box-shadow: 0px 3px 4px #d6d6d6;
					position: absolute;
					left: 25px;
					right: 25px;
					width: 90%;
					bottom: 45px;
					text-align: center;
					background: #8000BE;
					color: #fff;
					border: 1px solid #fff;
					cursor: pointer;
					letter-spacing: 0;
					border-radius: 6px;
					display: inline-block;
					vertical-align: top;
					overflow: hidden;
					padding: 10px 25px;
					font-size: 2rem;
					font-weight: 500;
					line-height: 2.4rem;
					text-decoration: none;
					transition: all 0.3s ease;
					z-index: 1;

					@media ${device.laptopMedium} {
						position: revert;
					}
					@media ${device.ipadLandscape} {
						position: relative;
						left: 0;
						bottom: 0;
						margin-top: 32px;
						width: 100%;
						display: block;
						padding: 10px;
						font-size: 18px;
					}

					&:hover {
						background: #fff;
						color: #8000BE;
						border-color: #fff;
						font-weight: 700;
						position: absolute;
						left: 25px;
						right: 25px;
						width: 90%;
						bottom: 45px;
						text-align: center;
						border: 1px solid #fff;
					}

					&:after {
						content: "";
						display: inline-block;
						width: 17px;
						height: 14px;
						margin-left: 8px;

						@media ${device.ipadLandscape} {
							padding: 10px 0 0;
							display: block;
							margin: auto;
							justify-content: center;
						}

						@media ${device.mobileMedium} {
							padding: 10px 0 0;
							display: block;
							margin: auto;
							justify-content: center;
						}
					}
        }

				.o-button {
					padding: 10px 25px;

					@media ${device.ipadLandscape} {
						padding: 10px;
					}
				}
			}

			.o-columns--two {
				@media ${device.mobileStart} {
					width: 50%;
				}

				@media ${device.ipadLandscape} {
					padding-bottom: 30px !important;
					width: 100%;
					margin: 30px auto 0;
				}
			}
    }
  }
`;

export const ConsiderationTesting = styled.div`

	.container-slider {
		max-width: 1188px;
		margin-left: auto;
		margin-right: auto;
		padding: 0 3rem;

		@media ${device.laptopMedium} {
			padding-left: 15px;
			padding-right: 15px;
			max-width: 718px;
		}
	}

  .carouselHead {
    padding-top: 41px;
    padding-bottom: 41px;

		@media ${device.laptopMedium} {
			padding: 32px 0 0 0;
		}

    p {
      color: #000000;
      font-weight: 700;
      text-align: center;
      font-size: 4.2rem;
      line-height: 5rem;

			@media ${device.laptopMedium} {
				padding: 2px 20px 20px 20px;
				font-size: 2.5rem;
    		line-height: 3rem;
			}
    }
  }
  .carouselSlides {
		flex: 0 0 100%;
		max-width: 100%;
		width: 100%;
		min-height: 1px;
		padding-right: 30px;
		padding-left: 30px;
		padding-top: 8rem;

		@media ${device.mobileMedium} {
			padding-right: 0;
			padding-left: 0;
		}

		.btn-wrapper-slider {
			position: relative;
			display: flex;
			justify-content: center;
			margin-top: 20px;
		}
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
		}

		.generic-slider-no-image {
			position: relative;
			padding-top: 0;

			.swiper-pagination {
				bottom: unset;
				top: 20px;
				background-color: #00FFD9;
				height: 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				max-width: 968px;
				margin: auto;
				text-align: center;
				left: 0;
				right: 0;
				position: absolute;
				transition: 0.3s opacity;
				z-index: 10;
				margin-top: 15px;

				@media ${device.laptopMedium} {
					width: 100%;
					top: 44px;
				}

				.swipper-texts1::after {
					content: "At the initial visit, perform a thorough ocular and medical history";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts2::after {
					content: "Obtain a pedigree";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts3::after {
					content: "Refer the patient for genetic counselling";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts4::after {
					content: "Work with the genetic counsellors to ensure appropriate genetic testing";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swipper-texts5::after {
					content: "Support the patient through the result";

					@media ${device.ipadLandscape} {
						content: "";
					}
				}

				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}

				.swiper-pagination-bullet {
					width: 68px;
					height: 68px;
					margin: 0px;
					opacity: 1;
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: #7323CA;
					cursor: pointer;
					border-radius: 100%;
					font-weight: 700;
					line-height: 4.7rem;
					font-size: 3.4rem;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #7323CA;

					@media ${device.laptopMedium} {
						width: 44px;
						height: 44px;
						font-size: 2.7rem;
					}

					&.active {
						background-color: #7323CA;
						color: #fff;

						&:before {
							color: #fff;
						}
					}

					&:before {
						font-weight: 700;
						line-height: 4.7rem;
						font-size: 3.4rem;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #7323CA;
					}

					&:after {
						font-weight: 500;
						line-height: 3.2rem;
						font-size: 2.2rem;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #8000BE;
						position: absolute;
						width: 211px;
						top: 80px;

						@media ${device.laptopair2max} {
							width: 200px;
						}
					}
				}

				.swiper-progress-bar-fill {
					position: absolute;
					width: 0;
					height: 8px;
					z-index: -1;
					background-color: #7323CA;
				}
  		}
		}
		.button {
			font-size: 2.2rem;
			font-weight: 500;
			line-height: 2.6rem;
			color: #fff;
			box-shadow: 0px 3px 4px #d6d6d6;
			margin: 5px 10px;
			border: 1px solid #fff;
			width: 145px;
			height: 48px;
			background-color: #7323CA;
			position: relative;
			display: table-cell;
			justify-content: center;
			align-items: center;
			border-radius: 4px;
			cursor: pointer;
			margin: 0px 15px 30px;
			padding: 0 0;

			@media ${device.laptopMedium} {
				width: 137px;
			}

			&.back-btn {
				&:before {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-right: 15px;
					transform: rotate(-180deg);
					position: relative;
					top: 1px;
				}
				&:hover {
					background: #fff;
					color: #8000BE;
					border-color: #fff;
					font-weight: 700;
				}
			}

			&.forward-btn {
				&:after {
					content: "";
					display: inline-block;
					height: 17px;
					width: 17px;
					margin-left: 15px;
					position: relative;
					top: 2px;
				}
				&:hover {
					background: #fff;
					color: #8000BE;
					border-color: #fff;
					font-weight: 700;
				}
			}
		}
	}
	.slick-slider {
		.item {
			max-width: 950px;
			margin-left: auto;
			margin-right: auto;

			.item__content {
				display: flex;
				margin: auto;
				padding-top: 245px;

				@media ${device.laptopair2max} {
					padding-top: 270px;
				}

				@media ${device.ipadLandscape} {
					padding-top: 80px;
				}

				@media ${device.laptopMedium} {
					display: block;
    			width: 100%;

				}

				.item__icon_slider {
					margin-right: 28px;
					@media ${device.laptopMedium} {
						margin: auto !important;
					}

					.media {
            margin-bottom: 16px;
						img {
							@media ${device.laptopMedium} {
								width: 30px;
								margin: 0 auto;
							}
						}
					}
				}

				.form-card-heading {
					font-size: 2.2rem;
					color: #8000BE;
					margin-bottom: 30px;
					display: none;

					@media ${device.laptopMedium} {
						margin: 0 auto 20px auto !important;
						display: block;
						text-align: center;
						margin-bottom: 20px;
						font-size: 1.8rem;
					}

					.item__icon {
						img {
							width: 64px;
							height: auto;
							margin: auto;
    					display: block;

							@media ${device.laptopMedium} {
								width: 41px;
							}
						}
					}
				}
				.o-paragraph {
					font-size: 2.2rem;
					font-weight: 500;
					line-height: 3.2rem;

					@media ${device.ipadLandscapemin} {
						margin-top: 0;
					}

					ul {
						li {
						font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
						}
					}

					@media ${device.minimumDesk} {
						margin-top: 0;
					}

					@media ${device.laptopMedium} {
						font-size: 1.8rem;
						line-height: 2.2rem;
					}
				}

				.wizard-paragraph {
					font-family: 'Gotham-Book', Arial, Helvetica, sans-serif;
					span {
						font-weight: normal;
					}

					@media ${device.laptopMedium} {
    				font-size: 1.8rem;
						line-height: 2.2rem !important;
            padding-bottom: 20px;
						max-width: 634px;
						margin: auto;
					}
				}
			}
		}
	}
`;

