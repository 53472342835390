export const hcpCFT: any = {
  mainMenu: "Main Navigational Menu HCP Retina Brazil (PT)",
  footerMenu: "Footer Navigation Menu HCP Retina Brazil (PT)",
  footer: "Footer - HCP - Retina Brazil (PT)",
  exitPopup: "Exit Popup HCP Brazil (PT)",
  hCPSwitcher: "HCP Switcher Modal Pop Up - Retina Brazil (PT)",
  topNav: "Top Navigation Menu HCP Retina Brazil (PT)",
  footerCallout: "HCP Homepage Footer Navigation Callout HTML - Canada",
  siteLogo: "Site logos - Retina Brazil (PT)",
  socialBlock: "Footer Social Icons",
  americanAcademy: "American Academy - Considerations For Testing",
  learnMore: "Learn More About - Considerations For Testing",
  topPara: "HCP - Considerations for testing - Top Para - Brazil (PT)",
  tabContent: "Considerations for Testing - Tabbed content - Retina Brazil (PT)",
  navigationCallout: "Navigational Callout - Considerations For Testing",
  footerReference: "Reference - Considerations For Testing - Retina Brazil (PT)",
  slider: "Slider Section - Considerations For Testing",
  banner: "Banner - HTML - CFT - HCP - Brazil (PT)",
  outsideTab: "Consideration testing outside Tab Content - Retina Brazil (PT)",
  stepContent: "Step",
  backToTopGTM: "back to top - considerations for testing - hcp",
  tabAttrs: {
    prevStepText: "Anterior",
    nextStepText:"Próximo",
    tabGTMLabels : [{
      tabButtonGTM: {
        'data-gtm-event-label': 'genetic testing process overview tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [
        {
          iconGTMs: [
            {
              'data-gtm-event-label': 'step 1 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 2 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 3 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 4 icon',
              'className': 'gtm-accordion'
            },
            {
              'data-gtm-event-label': 'step 5 icon',
              'className': 'gtm-accordion'
            }
          ],
          prevBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 1'
            },
            {
              'data-gtm-event-label': 'step 2 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 2'
            },
            {
              'data-gtm-event-label': 'step 3 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 3'
            },
            {
              'data-gtm-event-label': 'step 4 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 4'
            },
            {
              'data-gtm-event-label': 'step 5 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 5'
            }
          ],
          nextBtnGTMs: [
            {
              'data-gtm-event-label': 'step 1 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 1'
            },
            {
              'data-gtm-event-label': 'step 2 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 2'
            },
            {
              'data-gtm-event-label': 'step 3 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 3'
            },
            {
              'data-gtm-event-label': 'step 4 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 4'
            },
            {
              'data-gtm-event-label': 'step 5 button',
              'className': 'gtm-accordion',
              'aria-label': 'Ir para passo 5'
            }
          ]
        }
      ]
    },
    {
      tabButtonGTM: {
        'data-gtm-event-label': 'Discussing Results Tab',
        'className': 'gtm-accordion'
      },
      slidersGTM: [{
        iconGTMs: [
          {
            'data-gtm-event-label': 'Before the Genetic Test',
            'className': 'gtm-accordion'
          },
          {
            'data-gtm-event-label': 'After the Genetic Test',
            'className': 'gtm-accordion'
          },
          {
            'data-gtm-event-label': 'If a Genetic Test is Negative or Inconclusive Icon',
            'className': 'gtm-accordion'
          }
        ]
      }]
    }
  ]},
  footerClassName: "considerations-for-testing-footer"
}
